import { Box, Flex } from '@rebass/grid';
import React, { Component } from 'react';
import styled from 'styled-components';
import Envelope from '../../assets/images/inbox.svg';
import CallToAction from '../../components/CallToAction';
import Failed from '../../components/Failed';
import Sent from '../../components/Sent';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '', email: '', message: '', status: '' };
  }

  handleSubmit = (e) => {
    this.setState({ name: '', email: '', message: '' });
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact-reynaert', ...this.state }),
    })
      .then(() => this.setState({ status: 'sent' }))
      .catch((error) => this.setState({ status: 'failed ' }));

    e.preventDefault();
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  resetEmailStatus = () => {
    this.setState({ status: '' });
  };

  render() {
    const { name, email, message } = this.state;
    return (
      <SplitBackground>
        <ContactWrapper>
          <Box width={[1, 1 / 2]} p={3}>
            <Styledh1>Contact</Styledh1>
            <p>Laat me weten hoe ik u kan helpen.</p>
            <EnvelopeImg src={Envelope} alt="envelope" />
          </Box>
          <FormWrapper width={[1, 1 / 2]} p={3}>
            <form onSubmit={this.handleSubmit}>
              <input type="hidden" name="form-name" value="contact-reynaert" />
              <StyledLabel htmlFor="name">Naam:</StyledLabel>
              <TextInput
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={this.handleChange}
                minlength="3"
                maxlength="128"
              />
              <StyledLabel htmlFor="email">E-mail:</StyledLabel>
              <TextInput
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                minlength="3"
                maxlength="128"
              />
              <StyledLabel htmlFor="message">Bericht:</StyledLabel>
              <AreaText
                id="message"
                name="message"
                rows="8"
                value={message}
                onChange={this.handleChange}
                minlength="3"
                maxlength="255"
              />
              <CallToAction className="right" type="submit">
                Verzenden
              </CallToAction>
            </form>
          </FormWrapper>
          {this.state.status === 'sent' && <Sent resetEmailStatus={this.resetEmailStatus} />}
          {this.state.status === 'failed' && <Failed resetEmailStatus={this.resetEmailStatus} />}
        </ContactWrapper>
      </SplitBackground>
    );
  }
}

const SplitBackground = styled(Flex)`
  @media (min-width: 640px) {
    background: linear-gradient(to right, white 50%, #e9ae8c 50%);
  }
  justify-content: center;
`;

const ContactWrapper = styled(Flex)`
  max-width: 1128px;
  flex-grow: 1;
  flex-wrap: wrap;
  padding-bottom: 100px;
`;

const EnvelopeImg = styled.img`
  max-width: 70%;
  padding: 20px 0;
  @media (max-width: 639px) {
    display: none;
  }
`;

const FormWrapper = styled(Box)`
  background-color: white;

  border-radius: 5px;
  margin: 0 20px;
  @media (min-width: 640px) {
    margin-left: -30px;
    margin-top: 30px;
  }

  -webkit-box-shadow: -1px 7px 19px -1px rgba(117, 117, 117, 1);
  -moz-box-shadow: -1px 7px 19px -1px rgba(117, 117, 117, 1);
  box-shadow: -1px 7px 19px -1px rgba(117, 117, 117, 1);
`;

const Styledh1 = styled.h1`
  color: #d26426;
`;

const TextInput = styled.input`
  border-color: lightgrey;
  border-width: 0 0 1px 0;
  display: block;
  margin: 10px;
  width: 90%;
`;

const AreaText = styled.textarea`
  border-color: lightgrey;
  border-width: 0 0 1px 0;
  display: block;
  margin: 10px;
  resize: none;
  width: 90%;
`;

const StyledLabel = styled.label`
  margin: 10px;
`;
