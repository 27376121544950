import { Box, Flex } from '@rebass/grid';
import React, { Component } from 'react';
import styled from 'styled-components';
import FailedImg from '../assets/images/failed.svg';
import CallToAction from './CallToAction';

export default class Failed extends Component {
  render() {
    return (
      <FailedWrapper p={3}>
        <Centered>
          <FailedImgWrapper src={FailedImg} alt="verzonden" />
        </Centered>
        <Content>Bericht verzenden mislukt.</Content>
        <Content>Controleer aub uw verbinding of probeer het later opnieuw.</Content>
        <Content>U kan ook een mail sturen naar frank@reynaertadvocaten.be</Content>
        <CallToAction className="right" onClick={this.props.resetEmailStatus}>
          Sluiten
        </CallToAction>
      </FailedWrapper>
    );
  }
}

const FailedWrapper = styled(Box)`
  background-color: white;
  border-radius: 5px;
  box-shadow: -1px 7px 19px -1px rgba(117, 117, 117, 1);
  left: calc(50% - 150px);
  min-height: 200px;
  padding: 10px;
  position: absolute;
  top: 140px;
  width: 300px;
`;

const Content = styled.p`
  padding: 10px 0;
`;

const FailedImgWrapper = styled.img`
  padding-bottom: 20px;
  width: 200px;
`;

const Centered = styled(Flex)`
  justify-content: center;
  flex-wrap: wrap;
`;
