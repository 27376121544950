import { Box, Flex } from '@rebass/grid';
import React, { Component } from 'react';
import styled from 'styled-components';
import SentImg from '../assets/images/sent.svg';
import CallToAction from './CallToAction';

export default class Sent extends Component {
  render() {
    return (
      <SentWrapper p={3}>
        <Centered>
          <SentImgWrapper src={SentImg} alt="verzonden" />
        </Centered>
        <Centered>
          <p>Bericht verstuurd.</p>
        </Centered>
        <CallToAction className="right" onClick={this.props.resetEmailStatus}>
          Sluiten
        </CallToAction>
      </SentWrapper>
    );
  }
}

const SentWrapper = styled(Box)`
  background-color: white;
  border-radius: 5px;
  box-shadow: -1px 7px 19px -1px rgba(117, 117, 117, 1);
  left: calc(50% - 150px);
  min-height: 200px;
  padding: 10px;
  position: absolute;
  top: 140px;
  width: 300px;
`;

const SentImgWrapper = styled.img`
  padding-bottom: 20px;
  width: 200px;
`;

const Centered = styled(Flex)`
  justify-content: center;
`;
