import React from 'react';
import GlobalHead from '../config/GlobalHead';
import GlobalStyle from '../config/GlobalStyle';
import { Provider } from '../contexts/context';
import ContactWrapper from '../modules/contact/Contact';
import Footer from '../modules/footer/Footer';
import Header from '../modules/navigation/Header';

const Contact = () => (
  <Provider>
    <GlobalStyle />
    <GlobalHead />
    <Header />
    <ContactWrapper />
    <Footer />
  </Provider>
);

export default Contact;
